import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { CLUSTER_BACKGROUND, FONT_FAMILY, MenuProps } from '../utils/const';
import SearchBar from 'material-ui-search-bar';
import { Col, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ValueRankCluster from './ValueRankCluster';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: window.innerHeight - 275,
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
    cursor: 'pointer',
  },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
}));

export default function ValueRank({
  selectedDate,
  selectedProperty,
  executeScroll,
  sub_tab,
  rows_only,
  specailDates,
  dates_filter,
}) {
  const classes = useStyles();
  const [dates, setDates] = useState([]);
  const [sortDir, setSortDir] = useState('desc');

  const [sortBy, setSortBy] = useState(1);

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    err,
    clusterData,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    reqHotel,
    ratingCluster,
    date_format,
  } = getClusterDataSet;


  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const options = [2, 3, 4, 5];

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [hotelsList, setHotelsList] = useState([]);

  // const [hotelsList_wd, setHotelsList_wd] = useState([]);
  // const [hotelsList_we, setHotelsList_we] = useState([]);

  const [originalRows, setOriginalRows] = useState([]);

  const [binding, setBinding] = useState(true);

  const [searched, setSearched] = useState('');

  const report_len = daily_fetch_len + 1;

  const [load, setLoad] = useState(true);

  const [selectedStars, setSelectedStars] = useState([...options]);

  const isAllSelectedStars =
    options.length > 0 && selectedStars.length === options.length;

  const [tab, setTab] = useState(1);

  const requestSearch = (searchedVal) => {
    // setSearched(searchedVal);
    const filteredRows = originalRows.filter((row) => {
      return row.hotelName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setHotelsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const getFreqBucketMinMaxRatings = (stars, dt) => {
    // console.log(`dt: ${dt}, bucket: ${bucket}`);
    let min = -1;
    let max = -1;

    let hotelsArr = [];

    if (clusterData.length > 0) {
      if (clusterData[dt].length > 0) {
        clusterData[dt].map((bucket) => {
          if (bucket.unwanted.length > 0) {
            bucket.unwanted.map((_h) => {
              if (Math.floor(_h.stars) == Math.floor(stars)) {
                hotelsArr.push(_h);
              }
            });
          }
        });
      }
    }

    // if (hotelsArr.length > 0) {
    //   hotelsArr = hotelsArr.filter(
    //     (_h) => Math.floor(_h.stars) == Math.floor(stars)
    //   );
    // }

    if (hotelsArr.length > 0) {
      min = Math.min(...hotelsArr.map((item) => item.raings));

      max = Math.max(...hotelsArr.map((item) => item.raings));
    }

    return { min: min, max: max };
  };

  const getBucketMinMaxRate = (stars, dt) => {
    // console.log(`dt: ${dt}, bucket: ${bucket}`);
    let min = -1;
    let max = -1;

    let hotelsArr = [];

    if (clusterData.length > 0) {
      if (clusterData[dt].length > 0) {
        clusterData[dt].map((bucket) => {
          if (bucket.unwanted.length > 0) {
            bucket.unwanted.map((_h) => {
              if (Math.floor(_h.stars) == Math.floor(stars)) {
                hotelsArr.push(_h);
              }
            });
          }
        });
      }
    }

    // if (hotelsArr.length > 0) {
    //   hotelsArr = hotelsArr.filter(
    //     (_h) => Math.floor(_h.stars) == Math.floor(stars)
    //   );
    // }

    if (hotelsArr.length > 0) {
      min = Math.min(...hotelsArr.map((item) => item.rate));

      max = Math.max(...hotelsArr.map((item) => item.rate));
    }

    return { min: min, max: max };
  };

  const getReqHotelData = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
        }
      });
    }

    return name;
  };

  const getAvg = (arr) => {
    if (arr.length > 0) {
      return arr.reduce((a, b) => a + b) / arr.length;
    }
  };

  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  const calculateEuclidianDistance = (p1, p2) => {
    var diff_x = (p2.x / p1.x) * 100 - p1.x;
    var diff_y = (p2.y / p1.y) * 100 - p1.y;

    return Math.sqrt(diff_x * diff_x + diff_y * diff_y);

    // return diff_x + diff_y;
  };

  useEffect(() => {
    requestSearch(searched);
  }, [searched]);

  const getValueRanks = (arr) => {
    var sorted = arr
      .filter((e) => !isNaN(e.distance) && e.distance >= 0)
      .sort((a, b) => a.distance - b.distance || b.stars - a.stars);

    var rank_2 = 1;
    var rank_3 = 1;
    var rank_4 = 1;
    var rank_5 = 1;

    for (var i = 0; i < sorted.length; i++) {
      if (i > 0 && sorted[i].distance > sorted[i - 1].distance) {
        if (Math.floor(sorted[i].stars) == 2) {
          sorted[i].valueRank = rank_2++;
        } else if (Math.floor(sorted[i].stars) == 3) {
          sorted[i].valueRank = rank_3++;
        } else if (Math.floor(sorted[i].stars) == 4) {
          sorted[i].valueRank = rank_4++;
        } else if (Math.floor(sorted[i].stars) == 5) {
          sorted[i].valueRank = rank_5++;
        }
      } else if (i == 0) {
        if (Math.floor(sorted[i].stars) == 2) {
          sorted[i].valueRank = rank_2++;
        } else if (Math.floor(sorted[i].stars) == 3) {
          sorted[i].valueRank = rank_3++;
        } else if (Math.floor(sorted[i].stars) == 4) {
          sorted[i].valueRank = rank_4++;
        } else if (Math.floor(sorted[i].stars) == 5) {
          sorted[i].valueRank = rank_5++;
        }
      } else if (i > 0 && sorted[i].distance == sorted[i - 1].distance) {
        if (Math.floor(sorted[i].stars) == 2) {
          sorted[i].valueRank = rank_2;
        } else if (Math.floor(sorted[i].stars) == 3) {
          sorted[i].valueRank = rank_3;
        } else if (Math.floor(sorted[i].stars) == 4) {
          sorted[i].valueRank = rank_4;
        } else if (Math.floor(sorted[i].stars) == 5) {
          sorted[i].valueRank = rank_5;
        }
      }
    }

    // console.log(sorted);
    return sorted;
  };

  useEffect(() => {
    const buildDataset = async () => {
      setBinding(true);

      let ed_x_y_2 = [];
      let ed_x_y_3 = [];
      let ed_x_y_4 = [];
      let ed_x_y_5 = [];

      [...Array(report_len).keys()].map((obj, index) => {
        let all_hotels = [];

        hotels.map((_hotel) => {
          if (
            checkHotelAvailability(_hotel.hotelID, index) &&
            _hotel.prices[index] != null &&
            _hotel.prices[index] !== undefined
          ) {
            if (_hotel.availableDays !== undefined) {
              _hotel.availableDays = _hotel.availableDays + 1;
            } else {
              _hotel.availableDays = 1;
            }

            // let cluster_dt =
            //   getClusterByPrice(
            //     _hotel.prices[index].price[
            //       getPrice(_hotel.prices[index].price)
            //     ],
            //     index
            //   ) + 2;

            const FrqBucketMinMax = getFreqBucketMinMaxRatings(
              _hotel.stars,
              index
            );

            const bucketMinMaxRate = getBucketMinMaxRate(_hotel.stars, index);

            if (
              FrqBucketMinMax.min > 0 &&
              FrqBucketMinMax.max > 0 &&
              bucketMinMaxRate.min > 0 &&
              bucketMinMaxRate.max > 0
            ) {
              let FreqBucketRatingDiff =
                FrqBucketMinMax.max - FrqBucketMinMax.min > 0
                  ? FrqBucketMinMax.max - FrqBucketMinMax.min
                  : 1;

              let FreqBucketRateDiff =
                bucketMinMaxRate.max - bucketMinMaxRate.min > 0
                  ? bucketMinMaxRate.max - bucketMinMaxRate.min
                  : 1;

              let rating_index = parseFloat(
                Math.abs(FrqBucketMinMax.max - _hotel.ratings) /
                  FreqBucketRatingDiff
              );

              let rate_index = parseFloat(
                Math.abs(
                  _hotel.prices[index].price[
                    getPrice(_hotel.prices[index].price)
                  ] - bucketMinMaxRate.min
                ) / FreqBucketRateDiff
              );

              if (rating_index != Infinity && rate_index != Infinity) {
                _hotel.prices[index].rating_index = rating_index;
                _hotel.prices[index].rate_index = rate_index;
                _hotel.prices[index].distance = rating_index + rate_index;
              }
            }
          }
        });

        // options.map((op) => {
        //   let ed_x = Math.max(
        //     ...all_hotels
        //       .filter((e) => Math.floor(e.stars) == op)
        //       .map((o) => o.x)
        //   );
        //   let ed_y = Math.min(
        //     ...all_hotels
        //       .filter((e) => Math.floor(e.stars) == op)
        //       .map((o) => o.y)
        //   );

        //   if (op == 2) {
        //     ed_x_y_2.push({ x: ed_x, y: ed_y });
        //   } else if (op == 3) {
        //     ed_x_y_3.push({ x: ed_x, y: ed_y });
        //   } else if (op == 4) {
        //     ed_x_y_4.push({ x: ed_x, y: ed_y });
        //   } else if (op == 5) {
        //     ed_x_y_5.push({ x: ed_x, y: ed_y });
        //   }
        // });
      });

      // console.log(hotels);

      // console.log(ed_x_y_2);
      // console.log(ed_x_y_3);
      //console.log(ed_x_y_4);
      // console.log(ed_x_y_5);

      // hotels.map((_hotel) => {
      //   _hotel.prices.map((dt, ix) => {
      //     if (dt != null) {
      //       if (
      //         dt.rating_index &&
      //         _hotel.availableDays >= (report_len * 95) / 100
      //       ) {
      //         if (Math.floor(_hotel.stars) == 2) {
      //           dt.distance = parseFloat(
      //             calculateEuclidianDistance(ed_x_y_2[ix], {
      //               x: dt.rating_index,
      //               y: dt.price[getPrice(dt.price)],
      //             }).toFixed(2)
      //           );
      //         } else if (Math.floor(_hotel.stars) == 3) {
      //           dt.distance = parseFloat(
      //             calculateEuclidianDistance(ed_x_y_3[ix], {
      //               x: dt.rating_index,
      //               y: dt.price[getPrice(dt.price)],
      //             }).toFixed(2)
      //           );
      //         } else if (Math.floor(_hotel.stars) == 4) {
      //           dt.distance = parseFloat(
      //             calculateEuclidianDistance(ed_x_y_4[ix], {
      //               x: dt.rating_index,
      //               y: dt.price[getPrice(dt.price)],
      //             }).toFixed(2)
      //           );
      //         } else if (Math.floor(_hotel.stars) == 5) {
      //           dt.distance = parseFloat(
      //             calculateEuclidianDistance(ed_x_y_5[ix], {
      //               x: dt.rating_index,
      //               y: dt.price[getPrice(dt.price)],
      //             }).toFixed(2)
      //           );
      //         }
      //       } else {
      //         dt.distance = -1;
      //       }
      //     }
      //   });
      // });

      [...Array(report_len).keys()].map((d, i) => {
        let distance_arr = [];
        hotels.map((_hotel, id) => {
          if (_hotel.prices[i] != null) {
            if (
              _hotel.prices[i].distance != 'NaN' &&
              _hotel.prices[i].distance >= 0
            ) {
              let n_hotel = {
                hotelID: _hotel.hotelID,
                hotelName: _hotel.hotelName,
                stars: _hotel.stars,
                price: _hotel.prices[i].price[getPrice(_hotel.prices[i].price)],
                rating_index: _hotel.prices[i].rating_index,
                rate_index: _hotel.prices[i].rate_index,
                distance: _hotel.prices[i].distance,
              };
              distance_arr.push(n_hotel);
            }
          }
        });

        const ranks_arr = getValueRanks(distance_arr);

        // if (i == 0) {
        //   console.log(ranks_arr);
        // }

        hotels.map((_hotel, id) => {
          if (_hotel.prices[i] != null) {
            const exist = ranks_arr.filter(function (el) {
              return el.hotelID == _hotel.hotelID;
            });

            if (exist.length > 0) {
              _hotel.prices[i].valueRank = ranks_arr.find(
                (x) => x.hotelID == _hotel.hotelID
              ).valueRank;
            }
          }
        });
      });

      hotels.map((_hotel, id) => {
        // const rate_arr_wd = [];
        // const rate_arr_we = [];

        const ranks_arr = [];

        _hotel.prices.map((item, index) => {
          if (index <= report_len && item !== null) {
            if (item.valueRank) {
              ranks_arr.push(item.valueRank);
            }
          }
        });

        const ranks_arr_len = ranks_arr.filter((x) => x !== null).length;

        _hotel.ranks_arr_len = ranks_arr_len;

        _hotel.avg_valueRank =
          ranks_arr.reduce((a, b) => a + b, 0) / ranks_arr_len;
      });
      setBinding(false);
    };

    if (hotels.length > 0) {
      buildDataset();

      let reqHotelData = hotels.find(
        (o) => getReqHotelData().localeCompare(o.hotelName) == 0
      );

      const filtered_hotels = hotels.filter((e) => !isNaN(e.avg_valueRank));

      let value_rank_hotels = filtered_hotels.sort(
        (a, b) => a.avg_valueRank - b.avg_valueRank
      );

      setOriginalRows(value_rank_hotels);
      // console.log(value_rank_hotels);
      setHotelsList(value_rank_hotels);
    }
  }, [hotels]);

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const mode = (arr) => {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }

    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const handleHotelsFilter = async (event) => {
    if (event.target.value == 0) {
      const selectedHotels = [hotels[0]];
      user.application.candidate_properties.map((_filterHotel) =>
        originalRows.some((hotel) => {
          if (hotel.hotelID === _filterHotel.id) {
            selectedHotels.push(hotel);
          }
        })
      );
      setHotelsList(selectedHotels);
    } else if (event.target.value == 2) {
      if (ratingCluster.min_rating) {
        if (originalRows.length > 0) {
          setHotelsList(
            originalRows.filter((h) => h.ratings >= ratingCluster.min_rating)
          );
        }
      }
    } else {
      setHotelsList(originalRows);
    }
  };

  const getDateByIndex = (index) => {
    if (cluster1.length > 0) {
      if (index >= 0) {
        return cluster1[index].date;
      }
    }
  };

  useEffect(() => {
    const handleStarsSelect = () => {
      if (selectedStars.length > 0) {
        const selectedHotels = [];
        selectedStars.map((star) => {
          originalRows.some((_filterHotel) => {
            if (star === Math.floor(_filterHotel.stars)) {
              selectedHotels.push(_filterHotel);
            }
          });
        });
        setHotelsList(
          selectedHotels.sort((a, b) => a.avg_valueRank - b.avg_valueRank)
        );
      }
    };
    if (originalRows.length > 0) {
      handleStarsSelect();
    }
  }, [selectedStars]);

  const handleStarsChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedStars(selectedStars.length === options.length ? [] : options);
      return;
    }
    setSelectedStars(value);
  };

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 200 }}>
        {' '}
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              Stars - based
            </Nav.Link>
          </Nav.Item>{' '}
          <Nav.Item>
            <Nav.Link
              className={
                tab === 2
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(2)}
            >
              Bucket - based
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  };

  const GetValueRankGridRows = () => {
    const [value_rank, set_value_rank] = useState([]);
    const [value_rate, set_value_rate] = useState([]);

    const [subject_hotel_data, set_subject_hotel_data] = useState([]);

    useEffect(() => {
      const setValues = () => {
        let reqHotelData = hotels.find(
          (o) => getReqHotelData().localeCompare(o.hotelName) == 0
        );

        set_subject_hotel_data(reqHotelData);

        let value_rates = Array(report_len).fill('-');
        let value_ranks = Array(report_len).fill('-');

        if (hotelsList.length > 0) {
          [...Array(report_len).keys()].map((d, i) => {
            hotelsList.map((_hotel) => {
              const dt = _hotel.prices[i];

              if (dt != null) {
                if (_hotel.hotelID == reqHotelData.hotelID) {
                  value_ranks[i] = dt.valueRank;
                }

                if (
                  dt.valueRank == 1 &&
                  Math.floor(_hotel.stars) == Math.floor(reqHotelData.stars)
                ) {
                  value_rates[i] = dt.price[getPrice(dt.price)];
                }
              } else {
                if (_hotel.hotelID == reqHotelData.hotelID) {
                  value_ranks[i] = '-';
                }
              }
            });
          });
        }

        set_value_rank(value_ranks);
        set_value_rate(value_rates);
      };

      if (hotels.length > 0) {
        setValues();
      }
    }, []);

    return (
      <>
        {value_rank.length > 0 &&
        value_rate.length > 0 &&
        subject_hotel_data ? (
          <>
            <StyledTableRow>
              <StyledTableCell
                size="small"
                component="th"
                scope="col"
                className={classes.sticky}
                style={{ fontWeight: 'bold', width: '300px' }}
              >
                Value Rank - {subject_hotel_data.stars} Stars &nbsp;{' '}
                <i
                  class="fa fa-external-link"
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                  onClick={() => executeScroll(10, 0, sub_tab)}
                ></i>
              </StyledTableCell>

              {value_rank.map((e, index) =>
                (() => {
                  if (dates_filter == 1) {
                    return (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e}
                      </StyledTableCell>
                    );
                  } else if (dates_filter == 2) {
                    const found = specailDates.filter(
                      (obj) =>
                        moment(obj.date).format('YYYY-MM-DD') ==
                        moment(getDateByIndex(index)).format('YYYY-MM-DD')
                    );
                    if (found.length > 0) {
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          {e}
                        </StyledTableCell>
                      );
                    }
                  }
                })()
              )}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                size="small"
                component="th"
                scope="col"
                className={classes.sticky}
                style={{ fontWeight: 'bold', width: '300px' }}
              >
                Best Value Rate - {subject_hotel_data.stars} Stars &nbsp;{' '}
                <i
                  class="fa fa-external-link"
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                  onClick={() => executeScroll(10, 0, sub_tab)}
                ></i>
              </StyledTableCell>

              {value_rate.map((e, index) =>
                (() => {
                  if (dates_filter == 1) {
                    return (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          backgroundColor:
                            CLUSTER_BACKGROUND[getClusterByPrice(e, index)],
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e}
                      </StyledTableCell>
                    );
                  } else if (dates_filter == 2) {
                    const found = specailDates.filter(
                      (obj) =>
                        moment(obj.date).format('YYYY-MM-DD') ==
                        moment(getDateByIndex(index)).format('YYYY-MM-DD')
                    );
                    if (found.length > 0) {
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          {e}
                        </StyledTableCell>
                      );
                    }
                  }
                })()
              )}
            </StyledTableRow>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ReqHotelValueRank = () => {
    return (
      <TableContainer
      component={Paper}
      className={classes.container + ' mt-3'}
    >
      <Box width={100}>
        <Table
          className={classes.table}
          size="medium"
          aria-label="customized table"
          stickyHeader
          bodystyle={{ overflow: 'visible' }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                  width: '400px',
                  zIndex: 100,
                  fontFamily: FONT_FAMILY,
                }}
              >
                Hotel Name
                <hr />
                <TableSortLabel disabled>Days Out</TableSortLabel>
              </StyledTableCell>
              <StyledTableCell size="small">
                Stars
              </StyledTableCell>
              <StyledTableCell size="small">
                Ratings
              </StyledTableCell>

              {cluster1.map((e, index) =>
                (() => {
                  if (index < 180) {
                    let _date = moment(e.date, 'YYYY-MM-DD');
                    let daysOut = _date.diff(
                      selectedDate,
                      'days'
                    );
                    let day = _date
                      .format('dddd')
                      .substring(0, 3);
                    return (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={
                          day === 'Sat' || day === 'Fri'
                            ? 'bg-secondary text-light text-center'
                            : 'text-center'
                        }
                        style={{ fontSize: '12px' }}
                      >
                        <>
                          {day === 'Sat' || day === 'Fri'
                            ? 'WEND'
                            : 'WDAY'}
                        </>
                        <br />
                        <>{day.toUpperCase()}</>
                        <br />
                        <>
                          {moment(_date).format(date_format)}
                        </>{' '}
                        <div class="dropdown-divider"></div>
                        {daysOut}
                      </StyledTableCell>
                    );
                  }
                })()
              )}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {[hotelsList.find(_hotel => _hotel.hotelID == selectedProperty)].map((_hotel, index) => (
              <StyledTableRow>
                <StyledTableCell
                  size="medium"
                  component="th"
                  scope="col"
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '400px' }}
                >
                  {_hotel.hotelName}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  className={classes.rates}
                >
                  {_hotel.stars}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  className={classes.rates}
                >
                  {_hotel.ratings}
                </StyledTableCell>

                {_hotel.prices.map((dt, ix) => (
                  <>
                    {ix < 180 ? (
                      <>
                        {dt != null ? (
                          <>
                            <StyledTableCell
                              size="small"
                              className={classes.rates}
                              style={
                                checkHotelAvailability(
                                  _hotel.hotelID,
                                  ix
                                )
                                  ? {
                                      backgroundColor:
                                        CLUSTER_BACKGROUND[
                                          getClusterByPrice(
                                            dt.price[
                                              getPrice(dt.price)
                                            ],
                                            ix
                                          )
                                        ],
                                    }
                                  : { backgroundColor: '#9E9E9E' }
                              }
                            >
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`tooltip-${index}`}
                                  >
                                    {dt.price[getPrice(dt.price)]}
                                  </Tooltip>
                                }
                              >
                                <span className="font-weight-bold">
                                  {dt.valueRank == 1
                                    ? 'Best'
                                    : dt.valueRank}
                                </span>
                              </OverlayTrigger>
                            </StyledTableCell>
                          </>
                        ) : (
                          <>
                            <StyledTableCell
                              size="small"
                              className={classes.rates}
                            >
                              --
                            </StyledTableCell>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <br />
      </Box>
    </TableContainer>
    )
  }

  return (
    <>
      {hotels.length > 0 && originalRows.length > 0 && !binding ? (
        <>
          {rows_only ? (
            <GetValueRankGridRows />
          ) : (
            <>
              <TabularNav />
              <ReqHotelValueRank/>
              {tab === 1 ? (
                <>
                  <Grid container justify="space-evenly" className="my-3">
                    <FormGroup className={classes.formControl}>
                      <SearchBar
                        value={searched}
                        onChange={(searchVal) => setSearched(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                      />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                      <InputLabel
                        htmlFor="grouped-native-select"
                        style={{
                          backgroundColor: 'white',
                          fontFamily: FONT_FAMILY,
                        }}
                      >
                        Hotels Filter
                      </InputLabel>
                      <Select
                        native
                        id="grouped-native-select"
                        onChange={handleHotelsFilter}
                        style={{
                          backgroundColor: 'white',
                          fontFamily: FONT_FAMILY,
                        }}
                      >
                        <option value={1}>All Hotels</option>
                        <option value={2}>Best Rated Hotels</option>
                        <option value={0}>Analysis Set</option>
                      </Select>
                    </FormGroup>

                    <FormControl className={classes.formControl}>
                      <InputLabel id="mutiple-select-label">Stars</InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={selectedStars}
                        onChange={handleStarsChange}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelectedStars ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{
                                indeterminate: classes.indeterminateColor,
                              }}
                              checked={isAllSelectedStars}
                              indeterminate={
                                selectedStars.length > 0 &&
                                selectedStars.length < options.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox
                                checked={selectedStars.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <TableContainer
                    component={Paper}
                    className={classes.container + ' mt-3'}
                  >
                    <Box width={100}>
                      <Table
                        className={classes.table}
                        size="medium"
                        aria-label="customized table"
                        stickyHeader
                        bodystyle={{ overflow: 'visible' }}
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell size="small">#</StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: 'bold',
                                width: '400px',
                                zIndex: 100,
                                fontFamily: FONT_FAMILY,
                              }}
                            >
                              Hotel Name
                              <hr />
                              <TableSortLabel disabled>Days Out</TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell size="small">
                              Stars
                            </StyledTableCell>
                            <StyledTableCell size="small">
                              Ratings
                            </StyledTableCell>

                            {cluster1.map((e, index) =>
                              (() => {
                                if (index < 180) {
                                  let _date = moment(e.date, 'YYYY-MM-DD');
                                  let daysOut = _date.diff(
                                    selectedDate,
                                    'days'
                                  );
                                  let day = _date
                                    .format('dddd')
                                    .substring(0, 3);
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      className={
                                        day === 'Sat' || day === 'Fri'
                                          ? 'bg-secondary text-light text-center'
                                          : 'text-center'
                                      }
                                      style={{ fontSize: '12px' }}
                                    >
                                      <>
                                        {day === 'Sat' || day === 'Fri'
                                          ? 'WEND'
                                          : 'WDAY'}
                                      </>
                                      <br />
                                      <>{day.toUpperCase()}</>
                                      <br />
                                      <>
                                        {moment(_date).format(date_format)}
                                      </>{' '}
                                      <div class="dropdown-divider"></div>
                                      {daysOut}
                                    </StyledTableCell>
                                  );
                                }
                              })()
                            )}
                          </StyledTableRow>
                        </TableHead>

                        <TableBody>
                          {hotelsList.map((_hotel, index) => (
                            <StyledTableRow>
                              <StyledTableCell size="small">
                                {hotelsList.findIndex(
                                  (obj) => obj.hotelID == _hotel.hotelID
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                size="medium"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '400px' }}
                              >
                                {_hotel.hotelName}
                              </StyledTableCell>
                              <StyledTableCell
                                size="small"
                                className={classes.rates}
                              >
                                {_hotel.stars}
                              </StyledTableCell>
                              <StyledTableCell
                                size="small"
                                className={classes.rates}
                              >
                                {_hotel.ratings}
                              </StyledTableCell>

                              {_hotel.prices.map((dt, ix) => (
                                <>
                                  {ix < 180 ? (
                                    <>
                                      {dt != null ? (
                                        <>
                                          <StyledTableCell
                                            size="small"
                                            className={classes.rates}
                                            style={
                                              checkHotelAvailability(
                                                _hotel.hotelID,
                                                ix
                                              )
                                                ? {
                                                    backgroundColor:
                                                      CLUSTER_BACKGROUND[
                                                        getClusterByPrice(
                                                          dt.price[
                                                            getPrice(dt.price)
                                                          ],
                                                          ix
                                                        )
                                                      ],
                                                  }
                                                : { backgroundColor: '#9E9E9E' }
                                            }
                                          >
                                            <OverlayTrigger
                                              key="top"
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${index}`}
                                                >
                                                  {dt.price[getPrice(dt.price)]}
                                                </Tooltip>
                                              }
                                            >
                                              <span className="font-weight-bold">
                                                {dt.valueRank == 1
                                                  ? 'Best'
                                                  : dt.valueRank}
                                              </span>
                                            </OverlayTrigger>
                                          </StyledTableCell>
                                        </>
                                      ) : (
                                        <>
                                          <StyledTableCell
                                            size="small"
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <br />
                    </Box>
                  </TableContainer>
                </>
              ) : tab === 2 ? (
                <ValueRankCluster selectedDate={selectedDate} />
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
